<script lang="ts"></script>

<div class="error-block">
  <slot />
</div>

<style lang="scss">
  .error-block {
    margin-top: 40px;
    text-align: center;
  }
</style>
